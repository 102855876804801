body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* add print styles */

@media print {
  @page {
    size: portrait;
    display: none !important;
  }
  button {
    display: none !important;
  }
  .MuiCollapse-vertical {
    height: auto;
    visibility: visible;
  }
  .MuiDrawer-root, .mui-fixed, .MuiSvgIcon-root {
    display: none;
    visibility: hidden;
  }
  .MuiPaper-root {
    break-inside: avoid;
  }
}
